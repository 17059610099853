/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import ProductsGrid from "~components/ProductsGrid";
import SEO from "~components/SEO";

//

class ProductsPageComponent extends Component {
  mounted = false;

  lipOils = [];

  lipOilRefills = [];

  makeup = [];

  stuff = [];

  componentDidMount() {
    const { appContext, data } = this.props;
    this.mounted = true;

    appContext.setMenuActive(false);
    appContext.setNavText(`The future of beauty is more than makeup.`);

    data.allShopifyProduct.edges.forEach(({ node }) => {
      if (
        !node.images.length ||
        node.handle === `sticky-pack` ||
        node.handle.includes(`beanie`)
      ) {
        return;
      }

      data.allShopifyAdminProduct.edges.forEach(allShopifyAdminProduct => {
        if (allShopifyAdminProduct.node.products) {
          allShopifyAdminProduct.node.products.forEach(shopifyAdminProduct => {
            if (shopifyAdminProduct.handle === node.handle) {
              node.admin_variant_id =
                shopifyAdminProduct.variants[0].alternative_id;
            }
          });
        }
      });

      data.allMarkdownRemark.edges.forEach(edge => {
        const { frontmatter } = edge.node;

        if (frontmatter.handle === node.handle) {
          node.fm_title = frontmatter.title;
          node.discount = frontmatter.discount;
          node.preorder = frontmatter.preorder;
          node.soldOut = frontmatter.soldOut;
          node.featuredImage = frontmatter.featuredImage;
        }
      });

      if (
        node.handle === `bronzing-powder` ||
        node.handle === `bronzing-duet` ||
        node.handle === `kabuki-brush` ||
        node.handle === `face-oil` ||
        node.handle === `the-foundation`
      ) {
        this.makeup.push(node);
      } else if (
        node.handle === `lip-oil-1-clear-refill` ||
        node.handle === `lip-oil-2-tint-refill`
      ) {
        this.lipOilRefills.push(node);
      } else if (
        node.handle === `lip-oil-1-clear` ||
        node.handle === `lip-oil-2-tint`
      ) {
        this.lipOils.push(node);
      } else {
        this.stuff.push(node);
      }
    });

    this.makeup.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.stuff.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.lipOils.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.lipOilRefills.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  //

  addToCart = shopifyProduct => {
    const { cart } = this.props.appContext;

    const selectedProduct = {
      handle: shopifyProduct.handle,
      image: shopifyProduct.variants[0].image.originalSrc,
      name: shopifyProduct.title,
      price: shopifyProduct.variants[0].price,
      quantity: 1,
      variantId: shopifyProduct.admin_variant_id
    };

    if (cart && cart.length) {
      let added = false;

      cart.forEach(cartItem => {
        if (added) {
          return;
        }

        if (cartItem.variantId === selectedProduct.variantId) {
          added = true;
          cartItem.quantity += 1;
        }
      });

      if (!added) {
        selectedProduct.quantity = 1;
        cart.push(selectedProduct);
      }
    } else {
      selectedProduct.quantity = 1;
      cart.push(selectedProduct);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `Add Product`,
      price: selectedProduct.name
    });

    this.props.appContext.setCart(cart);
    this.props.appContext.setCartActive(true);
  };

  //

  render() {
    return (
      <>
        <SEO pageTitle="Products" pathName="/products" />

        <Layout className="products-page w-full gpu">
          <aside className="products-page__banner w-full relative bg-sun-yellow">
            <section className="grid relative w-full h-full">
              <div className="grid-end-6 xs:grid-end-12 text-black flex flex-col justify-around">
                <div>
                  <h1
                    className={`${
                      this.props.appContext.device === `mobile` ? `f1` : `d1`
                    } font-medium mb-8 animation-appear products-page__banner__text`}
                    style={{ animationDelay: `200ms` }}
                  >
                    Face Oil
                  </h1>
                </div>

                <div>
                  <h2
                    className={`${
                      this.props.appContext.device === `mobile` ? `f3` : `f2`
                    } font-medium animation-appear mb-8 `}
                    style={{ animationDelay: `300ms` }}
                  >
                    A practice that supports skin integrity, enhances its
                    appearance and relieves skin conditions.
                  </h2>
                </div>

                <Link to="/products/face-oil">
                  <button
                    type="button"
                    className="w-1/2 relative block mt-3 py-3 b-button text-sun-yellow hover:bg-white hover:text-black font-medium bg-black animation-appear mb-24"
                    style={{ animationDelay: `400ms` }}
                  >
                    Shop Now
                  </button>
                </Link>
              </div>

              <div
                className="products-page__banner--media grid-end-6 xs:grid-end-12 w-full h-full pointer-events-none animation-appear"
                style={{ animationDelay: `200ms` }}
              >
                <img
                  alt="Product page banner"
                  className="w-full h-full object-contain"
                  src={
                    this.props.data.markdownRemark.frontmatter.bannerMedia.src
                      .childImageSharp.fluid.src
                  }
                />
              </div>
            </section>
          </aside>

          <section
            className="products-grid__scroll animation-appear w-full relative pt-12 bg-white text-black"
            style={{ animationDelay: `200ms` }}
          >
            <div className="products-grid__scroll__flex products-grid__scroll__flex--clear products-grid__scroll__flex--heading flex flex-row flex-wrap items-stretch justify-between py-8 pt-16">
              <div className="products-grid__product w-1/3 relative mt-8 pt-4 px-6">
                <h3 className="f3 font-medium">Pre-order now</h3>

                <h3 className="products-grid__product__description mt-4 pr-8 f3 font-light">
                  Pre-order* our Lip Oil and get 1x free refills. That’s 2 Lip
                  Oils for the price of 1.
                </h3>
              </div>

              {this.lipOilRefills.map((product, index) => (
                <div
                  key={product.handle}
                  className="products-grid__product w-1/3 relative px-6 text-white"
                >
                  {product.discount !== null && product.discount > 0 && (
                    <div className="products-grid__product__discount absolute top-0 right-0 z-10 px-12 py-3 bg-periwinkle b2 font-medium">
                      SAVE ${product.discount}
                    </div>
                  )}

                  <Link to={`/products/${product.handle}`}>
                    <div className="products-grid__product__image w-full relative overflow-hidden gpu">
                      <Img
                        className="products-grid__product__image--gatsby"
                        fluid={product.featuredImage.childImageSharp.fluid}
                      />
                    </div>

                    <h2 className="mt-2 f3 font-medium text-black">
                      Lip Oil + Refill
                    </h2>

                    <h3 className="my-1 b1 font-medium text-black">
                      {`#${index + 1} ${product.title.substring(
                        product.title.lastIndexOf(`(`) + 1,
                        product.title.lastIndexOf(`)`)
                      )}`}
                    </h3>
                  </Link>

                  <button
                    type="button"
                    className="button w-full relative block mt-3 py-3 b-button text-sun-yellow font-bold bg-black"
                    onClick={() => this.addToCart(product)}
                  >
                    Pre-order: ${parseInt(product.variants[0].price)}
                  </button>

                  <h4
                    className="mt-3 b3 font-light text-black"
                    style={{
                      fontSize: `12px`
                    }}
                  >
                    *Ships Jan 31st
                  </h4>
                </div>
              ))}
            </div>

            <div className="products-grid__scroll__flex products-grid__scroll__flex--clear products-grid__scroll__flex--heading flex flex-row flex-wrap items-stretch justify-between py-8 pt-16 bg-white">
              <div className="products-grid__product w-1/3 relative mt-8 pt-4 px-6">
                <h3 className="f3 font-medium">Buy now</h3>

                <h3 className="products-grid__product__description mt-4 pr-8 f3 font-light">
                  Buy one of our limited edition Lip Oils today.
                </h3>
              </div>

              {this.lipOils.map((product, index) => (
                <div
                  key={product.handle}
                  className="products-grid__product w-1/3 relative px-6 text-white"
                >
                  {product.discount !== null && product.discount > 0 && (
                    <div className="products-grid__product__discount absolute top-0 right-0 z-10 px-12 py-3 bg-periwinkle b2 font-medium">
                      SAVE ${product.discount}
                    </div>
                  )}

                  <Link to={`/products/${product.handle}`}>
                    <div className="products-grid__product__image w-full relative overflow-hidden gpu">
                      <Img
                        className="products-grid__product__image--gatsby"
                        fluid={product.featuredImage.childImageSharp.fluid}
                      />
                    </div>

                    <h2 className="mt-2 f3 font-medium text-black">Lip Oil</h2>

                    <h3 className="my-1 b1 font-medium text-black">
                      {`#${index + 1} ${product.title.substring(
                        product.title.lastIndexOf(`(`) + 1,
                        product.title.lastIndexOf(`)`)
                      )}`}
                    </h3>
                  </Link>

                  {product.soldOut ? (
                    <Link to={`/products/${product.handle}`}>
                      <button
                        type="button"
                        className="button w-full relative block mt-3 py-3 b-button text-sun-yellow font-bold bg-black"
                      >
                        Sold Out
                      </button>
                    </Link>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="button w-full relative block mt-3 py-3 b-button text-sun-yellow font-bold bg-black"
                        onClick={() => this.addToCart(product)}
                      >
                        Buy Now: ${parseInt(product.variants[0].price)}
                      </button>
                      <h4
                        className="mt-3 b3 font-light text-black"
                        style={{
                          fontSize: `12px`
                        }}
                      >
                        *Ships immediately
                      </h4>
                    </>
                  )}
                </div>
              ))}
            </div>
          </section>

          <section
            className="animation-appear bg-white"
            style={{ animationDelay: `100ms` }}
          >
            <div className="grid">
              <h3 className="grid-end-12 py-8 f2 text-black font-medium">
                Shop all Products
              </h3>
            </div>
          </section>
          <ProductsGrid
            bgColor="bg-white"
            oneClick
            products={this.makeup.concat(this.stuff)}
          />
        </Layout>
      </>
    );
  }
}

const ProductsPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <ProductsPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default ProductsPage;

export const query = graphql`
  query ProductsPage($id: String!) {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            handle
            discount
            preorder
            soldOut
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  src
                }
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
  }
`;
